var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"information"},[_c('div',{staticClass:"content"},[_c('el-form',{attrs:{"label-position":"left","label-width":"100px"}},[_c('el-form-item',{attrs:{"label":"合同标题"}},[_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.data.contractTitle))])]),_c('el-form-item',{attrs:{"label":"客户"}},[_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.data.companyName))])]),_c('el-form-item',{attrs:{"label":"关联线索"}},[_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.$tableDataHandle.productCategory(_vm.data.productClue)))])]),_c('el-form-item',{attrs:{"label":"甲方"}},[_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.data.contractA))])]),_c('el-form-item',{attrs:{"label":"打款人"}},[_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.data.payContract))])]),_c('el-form-item',{attrs:{"label":"乙方"}},[(_vm.data.typeB != 9)?_c('span',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.$tableDataHandle.typeB(_vm.data.typeB))+" ")]):_vm._e(),(_vm.data.typeB == 9)?_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.data.contractB))]):_vm._e()]),_c('el-form-item',{attrs:{"label":"合同金额"}},[_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.data.contractAmount))])]),_c('el-form-item',{attrs:{"label":"已到款金额"}},[_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.data.paymentAmount))])]),_c('el-form-item',{attrs:{"label":"营业执照"}},[(
                        _vm.businessLicenseUrl.indexOf('.jpg') != -1 ||
                        _vm.businessLicenseUrl.indexOf('.png') != -1 ||
                        _vm.businessLicenseUrl.indexOf('.gif') != -1
                    )?_c('el-image',{staticStyle:{"width":"100px","height":"100px"},attrs:{"src":_vm.businessLicenseUrl,"preview-src-list":[_vm.businessLicenseUrl]}}):_vm._e(),(
                        _vm.businessLicenseUrl.indexOf('.pdf') != -1 ||
                        _vm.businessLicenseUrl.indexOf('.PDF') != -1
                    )?_c('span',{staticClass:"pdf",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.onPreview(_vm.businessLicenseUrl)}}},[_c('img',{attrs:{"src":require("@/img/pdfUrl.png"),"alt":""}})]):_vm._e(),(_vm.businessLicenseUrl.indexOf('.doc') != -1)?_c('span',{staticClass:"word",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.onPreview(_vm.businessLicenseUrl)}}},[_c('img',{attrs:{"src":require("@/img/word.png"),"alt":""}})]):_vm._e()],1),_c('el-form-item',{attrs:{"label":"附件"}},_vm._l((_vm.contractAttachmentList),function(item){return _c('div',[(
                            item.attachmentUrl.indexOf('.jpg') != -1 ||
                            item.attachmentUrl.indexOf('.png') != -1 ||
                            item.attachmentUrl.indexOf('.gif') != -1
                        )?_c('el-image',{staticStyle:{"width":"100px","height":"100px"},attrs:{"src":item.attachmentUrl,"preview-src-list":[item.attachmentUrl]}}):_vm._e(),(
                            item.attachmentUrl.indexOf('.pdf') != -1 ||
                            item.attachmentUrl.indexOf('.PDF') != -1
                        )?_c('span',{staticClass:"pdf",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.onPreview(item.attachmentUrl)}}},[_c('img',{attrs:{"src":require("@/img/pdfUrl.png"),"alt":""}})]):_vm._e(),(item.attachmentUrl.indexOf('.doc') != -1)?_c('span',{staticClass:"word",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.onPreview(item.attachmentUrl)}}},[_c('img',{attrs:{"src":require("@/img/word.png"),"alt":""}})]):_vm._e()],1)}),0),_c('el-form-item',{attrs:{"label":"备注"}},[_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.data.remark))])]),_c('el-form-item',{attrs:{"label":"部门"}},[_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.data.departmentName))])]),_c('el-form-item',{attrs:{"label":"商务"}},[_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.data.adminName))])]),_c('p',{staticStyle:{"font-size":"14px","padding-bottom":"20px"}},[_vm._v(" 邮箱类产品信息 ")]),_c('el-form-item',{attrs:{"label":"邮箱版本"}},[_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.data.emailVersionType == 1 ? '全新用户' : _vm.data.emailVersionType == 2 ? '已有新版基础版' : _vm.data.emailVersionType == 3 ? '已有旧版基础版' : _vm.data.emailVersionType == 4 ? '阿里-标准版' : _vm.data.emailVersionType == 5 ? '阿里-尊享版' : _vm.data.emailVersionType == 6 ? '阿里-集团版' : '- -'))])]),_c('el-form-item',{attrs:{"label":"类型信息"}},[_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.data.emailVersionTypeName))])]),_c('el-form-item',{attrs:{"label":"订单类型"}},[_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.data.orderType == 1 ? '新增' : _vm.data.orderType == 2 ? '续费' : _vm.data.orderType == 3 ? '扩用户' : '- -'))])])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }