<template>
    <div class="information">
        <div class="content">
            <el-form label-position="left" label-width="100px">
                <el-form-item label="合同标题">
                    <span class="text">{{ data.contractTitle }}</span>
                </el-form-item>
                <el-form-item label="客户">
                    <span class="text">{{ data.companyName }}</span>
                </el-form-item>
                <el-form-item label="关联线索">
                    <span class="text">{{
                        $tableDataHandle.productCategory(data.productClue)
                    }}</span>
                </el-form-item>
                <el-form-item label="甲方">
                    <span class="text">{{ data.contractA }}</span>
                </el-form-item>
                <el-form-item label="打款人">
                    <span class="text">{{ data.payContract }}</span>
                </el-form-item>
                <el-form-item label="乙方">
                    <span class="text" v-if="data.typeB != 9">
                        {{ $tableDataHandle.typeB(data.typeB) }}
                    </span>
                    <span class="text" v-if="data.typeB == 9">{{
                        data.contractB
                    }}</span>
                </el-form-item>
                <el-form-item label="合同金额">
                    <span class="text">{{ data.contractAmount }}</span>
                </el-form-item>
                <el-form-item label="已到款金额">
                    <span class="text">{{ data.paymentAmount }}</span>
                </el-form-item>
                <el-form-item label="营业执照">
                    <el-image
                        v-if="
                            businessLicenseUrl.indexOf('.jpg') != -1 ||
                            businessLicenseUrl.indexOf('.png') != -1 ||
                            businessLicenseUrl.indexOf('.gif') != -1
                        "
                        style="width: 100px; height: 100px"
                        :src="businessLicenseUrl"
                        :preview-src-list="[businessLicenseUrl]"
                    >
                    </el-image>
                    <span
                        class="pdf"
                        v-if="
                            businessLicenseUrl.indexOf('.pdf') != -1 ||
                            businessLicenseUrl.indexOf('.PDF') != -1
                        "
                        @click="onPreview(businessLicenseUrl)"
                        style="cursor: pointer"
                    >
                        <img src="@/img/pdfUrl.png" alt=""
                    /></span>
                    <span
                        class="word"
                        v-if="businessLicenseUrl.indexOf('.doc') != -1"
                        @click="onPreview(businessLicenseUrl)"
                        style="cursor: pointer"
                    >
                        <img src="@/img/word.png" alt="" />
                    </span>
                </el-form-item>
                <el-form-item label="附件">
                    <div v-for="item in contractAttachmentList">
                        <el-image
                            v-if="
                                item.attachmentUrl.indexOf('.jpg') != -1 ||
                                item.attachmentUrl.indexOf('.png') != -1 ||
                                item.attachmentUrl.indexOf('.gif') != -1
                            "
                            style="width: 100px; height: 100px"
                            :src="item.attachmentUrl"
                            :preview-src-list="[item.attachmentUrl]"
                        >
                        </el-image>
                        <span
                            class="pdf"
                            v-if="
                                item.attachmentUrl.indexOf('.pdf') != -1 ||
                                item.attachmentUrl.indexOf('.PDF') != -1
                            "
                            @click="onPreview(item.attachmentUrl)"
                            style="cursor: pointer"
                        >
                            <img src="@/img/pdfUrl.png" alt="" />
                        </span>
                        <span
                            class="word"
                            v-if="item.attachmentUrl.indexOf('.doc') != -1"
                            @click="onPreview(item.attachmentUrl)"
                            style="cursor: pointer"
                        >
                            <img src="@/img/word.png" alt="" />
                        </span>
                    </div>
                </el-form-item>
                <el-form-item label="备注">
                    <span class="text">{{ data.remark }}</span>
                </el-form-item>
                <el-form-item label="部门">
                    <span class="text">{{ data.departmentName }}</span>
                </el-form-item>
                <el-form-item label="商务">
                    <span class="text">{{ data.adminName }}</span>
                </el-form-item>
                <p style="font-size: 14px; padding-bottom: 20px">
                    邮箱类产品信息
                </p>
                <el-form-item label="邮箱版本">
                    <span class="text">{{
                        data.emailVersionType == 1
                            ? '全新用户'
                            : data.emailVersionType == 2
                            ? '已有新版基础版'
                            : data.emailVersionType == 3
                            ? '已有旧版基础版'
                            : data.emailVersionType == 4
                            ? '阿里-标准版'
                            : data.emailVersionType == 5
                            ? '阿里-尊享版'
                            : data.emailVersionType == 6
                            ? '阿里-集团版'
                            : '- -'
                    }}</span>
                </el-form-item>
                <el-form-item label="类型信息">
                    <span class="text">{{ data.emailVersionTypeName }}</span>
                </el-form-item>
                <el-form-item label="订单类型">
                    <span class="text">{{
                        data.orderType == 1
                            ? '新增'
                            : data.orderType == 2
                            ? '续费'
                            : data.orderType == 3
                            ? '扩用户'
                            : '- -'
                    }}</span>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            data: {},
            businessLicenseUrl: '',
            contractAttachmentList: [],
        };
    },
    methods: {
        getData() {
            this.data = this.$formDetails.state.data;
            // console.log(this.data);
            setTimeout(() => {
                this.businessLicenseUrl = this.data.businessLicenseUrl;
                this.contractAttachmentList = this.data.contractAttachmentList;
            }, 50);
        },
        onPreview(url) {
            if (url.indexOf('.doc') != -1 || url.indexOf('.docx') != -1) {
                window.open(
                    'https://view.officeapps.live.com/op/view.aspx?src=' + url
                );
            } else {
                window.open(url, '_blank');
            }
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.information {
    width: 100%;
    height: 90%;
    overflow: auto;

    .content {
        flex: 1;
        margin-left: 28px;
    }
    .text {
        font-weight: 400;
        color: #333333;
        line-height: 20px;
    }
    .el-form-item__label {
        font-size: 14px;
        font-weight: 400;
        color: #666666 !important;
        line-height: 20px;
    }
}
</style>
